import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

class Pagination extends React.Component {
    render() {

        const { pages, current, slug } = this.props; 

        if (pages > 1) {
            return (
                <PaginationList>
                 {Array.from({ length: pages }, (_, i) => (
                    <li key={`pagination-number${i + 1}`} className={`${current === i ? 'active' : ''}`}>
                      <Link key={`pagination-number${i + 1}`} to={`${slug}/${i === 0 ? "" : i + 1}#main-content`}>
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                </PaginationList>   
            )
        } else {
            return('');
        }
    }
}

export default Pagination


const PaginationList = styled.ul`
  margin: 25px auto;
  display: flex;
  justify-content: center;

  li {
    list-style: none;
    margin: 0 8px;
    height: 35px;
    width: 35px;

    a {
      color: #fff;
      text-decoration: none;
      background-color: #97D7E2;
      padding: 5px 12px;
      border-radius: 100%;
      text-align: center;
      font-size: 20px;

      &:hover,
      &[aria-current="page"] {
        background: #3c7db6;
      }
    }
  }
` 