import * as React from "react";
import ArticleIcon from '../../images/type-icon-article.png';
import WebinarIcon from '../../images/type-icon-webinar.png';
import BlogIcon from '../../images/type-icon-blog.png';

import "./module.featuredteaser.css";

function TypeIcon(Type) {
    if (Type === 'article') {
        return ArticleIcon;
    } 

    if (Type === 'webinar') {
        return WebinarIcon;
    }

    return BlogIcon;
}

function ArticleTeaser({article}) {

    const Type = article.field_type;
    const articleClass = 'type-' + Type;
    let Link = article.field_link;
    let Tag = article.field_link !== null ? 'a' : 'div';
    let LinkUri = Link ? Link.uri : '';

    if (article.relationships.field_file !== null) {
        Tag = 'a';
        Link = article.relationships.field_file.localFile.publicURL;
        LinkUri = Link;
    }

    return(
        <div className={`${articleClass} article-teaser`}>
            <img className="type-icon" src={TypeIcon(Type)} alt="" />
            <Tag {...(Link !== null ? {href: LinkUri, target: '_blank'} : '')}>
                <h3 className="card-title">{article.title}</h3>
                {article.field_in_co_operation_with && <span className="in-cooperation">In cooperation with {article.field_in_co_operation_with}</span>}
                <span className="date">{article.created}</span>
            </Tag>
        </div>
    )

}

export default ArticleTeaser;