import React from 'react';
import { Link } from "gatsby";

import "./module.filter.css";

function Filter({ page }) {

    return(
        <ul className="featured-filter">
            <li>
                <Link to="/featured#main-content" className={`filter-all ${page === undefined ? "active" : ""}`} activeClassName="active">All</Link>
            </li>
            <li>
                <Link to="/featured/articles#main-content" className={`filter-articles ${page === "article" ? "active" : ""}`} activeClassName="active">Articles</Link>
            </li>
            <li>
                <Link to="/featured/webinars#main-content" className={`filter-webinars ${page === "webinar" ? "active" : ""}`}>Webinars</Link>
            </li>
            <li>
                <Link to="/featured/blogs#main-content" className={`filter-blogs ${page === "blog" ? "active" : ""}`}>Blogs</Link>
            </li>
        </ul>
    );
}
 

export default Filter;