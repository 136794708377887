import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { componentResolver } from "../helpers/component-resolver";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero/hero";
import Filter from "../components/featured/Filter";
import FeaturedList from '../components/featured/FeaturedList';

function FeaturedCategory( data ) {
    const items = data.data.allNodeArticle.edges;
    const { currentPage, numPages, type } = data.pageContext;
    const page = data.data.nodePage;
    const bannerText = page.field_banner_text ? page.field_banner_text : page.title;
    const bannerImage = page.relationships.field_image ? page.relationships.field_image.localFile.publicURL : ``;
    const sections = componentResolver(page?.relationships?.field_sections);

    const seoTitle = 'Operational Risk Intelligence ' + type + 's';

    return(
        <Layout>
           <Seo title={seoTitle} />
           <Hero text={bannerText} image={bannerImage} frontClass='not-front-hero' />

           <React.Fragment >{sections[0]}</React.Fragment>
           <span id="main-content"></span>
            <StyledWrapper>
                <Filter page={type}/>
                <FeaturedList items={items} pages={numPages} current={currentPage} slug={`/featured/${type}s`}  />
            </StyledWrapper>

            <React.Fragment >{sections[2]}</React.Fragment>
        </Layout>
    )

}

export default FeaturedCategory;


export const pageQuery = graphql`
  query FeaturedCategoryQuery($skip: Int!, $limit: Int!, $type: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allNodeArticle(filter: {field_type: {eq: $type }}, sort: {fields: created, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
            title
            field_type
            created(formatString: "MMMM YYYY")
            field_in_co_operation_with
            field_link {
                uri
            }
            relationships {
                field_file {
                    localFile {
                    publicURL
                    }
                }
            }
        }
      }
    }
    nodePage(drupal_internal__nid: {eq: 3}) {
        title
        field_banner_text
        relationships {
            field_image {
                localFile {
                    publicURL
                }
            }
            field_sections {
                __typename
                ...ParagraphTextWithBackgroundColour
                ...ParagraphRiskForum
            }
        }
      }
  }
`;

const StyledWrapper = styled.div`
    max-width: 1300px;
    margin: 50px auto;
`