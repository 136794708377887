import React from 'react';
import Pagination from '../pagination';
import ArticleTeaser from "./FeaturedTeaser";

import "./module.featuredlist.css";

function FeaturedList({ items, pages, current, slug}) {

    return(
        <>
              <div className="articles-wrapper">
                    {items.map(({ node }) => {
                        return (
                            <ArticleTeaser key={node.title} article={node} />
                        )
                    })}
                </div>
                <Pagination pages={pages} current={current} slug={slug} />
        </>
    );

}

export default FeaturedList;